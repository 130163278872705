import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = {
    content: String,
    mimeType: String
  };

  connect() {
  }

  write() {
    const blob = new Blob([this.contentValue], { type: this.mimeTypeValue });
    
    navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob,
      }),
    ]);
  }
}
